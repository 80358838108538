import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_vx7hixfe76h3eheqblxwih5vtu/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Loader"] */ "/vercel/path0/src/components/atoms/loader/Loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarFallback","AvatarImage"] */ "/vercel/path0/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/features/auth/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserDropdown"] */ "/vercel/path0/src/features/auth/UserDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/vercel/path0/src/features/notifications/Notifications.tsx");
